import { Redirect } from 'wouter'

import { MayhemPageLoader } from '../../components/MayhemPageLoader'

import { link } from './utils'

import { useGetDefaultWorkspace } from '@/hooks'
import { ErrorPage } from '@/components/layout/ErrorPage'

export const WorkspaceDefaultRedirect = () => {
  const { defaultWorkspace, isLoading, isError } = useGetDefaultWorkspace()
  if (isLoading) {
    return <MayhemPageLoader />
  }
  if (isError) {
    return <ErrorPage errorMessage="Something went wrong! Please try refreshing the page." />
  }
  return <Redirect to={link(defaultWorkspace!)} />
}
