import { FormControl, FormHelperText, FormLabel, Heading, Stack, Switch, useToast } from '@chakra-ui/react'

import { useGetUserSettingQuery, usePutUserSettingMutation } from '../../redux/api/workspace'

export function AdminEnableNonadminWorkspaceCreationForm() {
  const toast = useToast()
  const { data: userSetting, isLoading: isLoadingUserSetting } = useGetUserSettingQuery()
  const [putUserSetting] = usePutUserSettingMutation()

  const onChange = async (enabled: boolean) => {
    try {
      await putUserSetting({ userSetting: { nonadmin_workspace_creation_enabled: enabled } }).unwrap()
      toast({
        title: `Successfully ${enabled ? 'enabled' : 'disabled'} non-admin workspace creation`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (err: unknown) {
      const message = (err as { data?: Error })?.data?.message || 'An error occurred. Please try again.'
      toast({
        title: `Failed to ${enabled ? 'enable' : 'disable'} non-admin workspace creation`,
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  return (
    <Stack spacing={4}>
      <Heading size="md">Workspace Creation</Heading>
      <FormControl display="grid" width="max-content">
        <FormLabel gridRow={1}>Non-admin workspace creation: {userSetting?.nonadmin_workspace_creation_enabled ? 'Enabled' : 'Disabled'}</FormLabel>
        <Switch
          gridRow={1}
          isChecked={userSetting?.nonadmin_workspace_creation_enabled}
          onChange={(e) => onChange(e.target.checked)}
          isDisabled={isLoadingUserSetting}
        />
        <FormHelperText maxW={96} gridRow={2}>
          Allow non-admin users to create workspaces.
        </FormHelperText>
      </FormControl>
    </Stack>
  )
}
