import { Box, Hide, Show, SimpleGrid, Stack, useColorMode } from '@chakra-ui/react'

import { WorkspaceNewProjectCard } from './WorkspaceNewProjectCard'

type Props = { workspaceSlug: string }

export function WorkspaceNewProjectCards({ workspaceSlug }: Props) {
  const colorMode = useColorMode().colorMode

  const cardsConfig = {
    dsbom: {
      link: `/${workspaceSlug}/-/dynamic-sbom-start`,
      title: 'Add Dynamic SBOM',
      text: 'Find reachable dependencies and CVEs with Mayhem’s Dynamic SBOM',
      innerCardItems: ['Prioritize CVEs based on reachability', 'Average 60-90% reduction in noise', 'Integrate existing SPDX/CycloneDX'],
      buttonText: 'Dynamic SBOM'
    },
    api: {
      link: `/${workspaceSlug}/-/create-api-run`,
      title: 'Test API',
      text: 'Optimize security and performance in your APIs with AI-driven testing.',
      innerCardItems: ['Easily test from Postman collection or OpenAPI spec', 'AI-driven behavior testing, OWASP 25 checks, ZAP integration'],
      buttonText: 'Add API'
    },
    code: {
      link: `/${workspaceSlug}/-/create-code-run`,
      title: 'Test Code',
      text: 'Mayhem’s generative AI, fuzz testing and symbolic execution pinpoint vulnerabilities',
      innerCardItems: [
        'Coverage-optimized algorithms find new paths and unknown vulnerabilities',
        'Automated regression testing and mitigation factor analysis'
      ],
      buttonText: 'Add Code'
    }
  }

  return (
    <Box
      bgGradient={
        colorMode === 'light'
          ? 'linear(to-r, rgba(230, 221, 255, 0.3) 26.69%, rgba(220, 224, 255, 0.3) 72.63%)'
          : 'linear(to-r, rgba(0, 24, 255, 0.10) 27%, rgba(101, 0, 255, 0.10) 75%)'
      }
      borderRadius="4xl"
      paddingX={10}
      paddingY={{ base: 20, xl: 36 }}
      display="flex"
      justifyContent="center"
    >
      {/* On large screens: display horizontally */}
      <Show above="xl">
        {/* Use CSS grid to ensure that cards' titles, texts, inner cards are aligned regardless of the text contents */}
        {/* Used (ugly) maxWidth="3 columns + 2 spaces" because cleaner alternative didn't work */}
        {/* - templateColums="repeat(3, fit-columns(var(--chakra-sizes-lg)))" -> impossible to center elements */}
        <SimpleGrid spacingX={8} columns={3} maxWidth="calc(3 * var(--chakra-sizes-lg) + 2 * 2rem)">
          <WorkspaceNewProjectCard {...cardsConfig.dsbom} gridCol={1} />
          <WorkspaceNewProjectCard {...cardsConfig.api} gridCol={2} />
          <WorkspaceNewProjectCard {...cardsConfig.code} gridCol={3} />
        </SimpleGrid>
      </Show>
      {/* On small screens: display vertically */}
      <Hide above="xl">
        <Stack width="full" spacing={8}>
          {/* Create 1 grid for each card */}
          <SimpleGrid>
            <WorkspaceNewProjectCard {...cardsConfig.dsbom} gridCol={1} />
          </SimpleGrid>
          <SimpleGrid>
            <WorkspaceNewProjectCard {...cardsConfig.api} gridCol={1} />
          </SimpleGrid>
          <SimpleGrid>
            <WorkspaceNewProjectCard {...cardsConfig.code} gridCol={1} />
          </SimpleGrid>
        </Stack>
      </Hide>
    </Box>
  )
}
