import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal, useDisclosure, useToast } from '@chakra-ui/react'

import { DeleteIcon, EditIcon, SettingsIcon } from '@chakra-ui/icons'

import { useDeleteProjectRegistryMutation } from '../../redux/api/docker'

import { RegistryModal } from './RegistryModal'
import { Registry } from './utils'

interface RegistryEditActionProps {
  registry: Registry
}

export function RegistryEditAction({ registry }: RegistryEditActionProps) {
  const [deleteRegistry] = useDeleteProjectRegistryMutation()

  const toast = useToast()

  const handleDeleteButtonClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    try {
      await deleteRegistry({
        owner: registry.workspaceSlug,
        projectSlug: registry.projectSlug,
        dockerRegistryId: registry.registryID
      }).unwrap()
      toast({
        title: 'Registry deleted',
        status: 'success',
        duration: 5000
      })
    } catch (error) {
      toast({
        title: 'Failed to delete registry',
        status: 'error',
        duration: 5000
      })
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={SettingsIcon} boxSize={6} />}
        variant="ghost"
        aria-label="Registry Actions"
        title="Registry Actions"
      />
      <Portal>
        <MenuList>
          <MenuItem
            icon={<Icon as={EditIcon} boxSize="4" />}
            onClick={(e) => {
              e.stopPropagation()
              onOpen()
            }}
          >
            Edit
          </MenuItem>
          <MenuItem icon={<Icon as={DeleteIcon} />} onClick={handleDeleteButtonClick}>
            Delete
          </MenuItem>
        </MenuList>
      </Portal>
      <RegistryModal
        owner={registry.workspaceSlug}
        projectSlug={registry.projectSlug}
        isOpen={isOpen}
        onClose={onClose}
        registry={registry}
        isEditMode={true}
      />
    </Menu>
  )
}
