import { useLocation } from 'wouter'
import { Alert, AlertIcon, Avatar, Button, Card, CardBody, CardHeader, Grid, GridItem, Heading, Stack, Text, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import ErrorIcon from '@material-design-icons/svg/sharp/error.svg?react'

import { Page } from '@/components/Page'
import { useAppDispatch, useQuery } from '@/hooks'
import { useAcceptAccountInviteMutation } from '@/redux/api/invites'
import { getJoinWorkspacePageTitle } from '@/titles'

export function JoinWorkspace() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    document.title = getJoinWorkspacePageTitle()
  }, [dispatch])

  const query = useQuery()
  const [_, setLocation] = useLocation()
  const accountSlug = query.get('workspace') || ''
  const invitationToken = query.get('token') || ''
  const [acceptInvite] = useAcceptAccountInviteMutation()
  const [error, setError] = useState('')
  const handleJoin = async () => {
    try {
      await acceptInvite({ accountInvitationToken: { invitation_token: invitationToken } }).unwrap()
      setLocation(`/${accountSlug}`, { replace: true })
    } catch (error) {
      if ((error as { status: number })?.status == 409) {
        // already a part of this workspace, forward to workspace
        setLocation(`/${accountSlug}`, { replace: true })
      }
      if ((error as { status: number })?.status == 404) {
        setError('Invalid invite, unable to join workspace. Please contact the workspace owner to receive a new invite.')
      } else {
        // Pass along all other errors to the user directly.
        //  - 403 is returned when token is correct, but email is wrong
        setError((error as { data?: { message?: string } })?.data?.message || 'Unable to join team.')
      }
    }
  }
  return (
    <Page>
      <Stack spacing={8}>
        <Card align="center">
          <CardHeader>
            <VStack>
              <Heading>We found your Team!</Heading>
              <Text>Join your team&apos;s workspace to collaborate with them on shared projects.</Text>
            </VStack>
          </CardHeader>
          <CardBody>
            <Grid templateRows="repeat(3, 1fr)" templateColumns="repeat(10, 1fr)" gap={8} alignItems="center">
              <GridItem rowSpan={1} colSpan={10}></GridItem>
              <GridItem rowSpan={2} colSpan={2}></GridItem>
              <GridItem rowSpan={2} colSpan={1}>
                <Avatar name={accountSlug} bg="gray.400" color="white"></Avatar>
              </GridItem>
              <GridItem rowSpan={2} colSpan={2}>
                <Text>{accountSlug}</Text>
              </GridItem>
              <GridItem rowSpan={2} colSpan={1}></GridItem>
              <GridItem rowSpan={2} colSpan={2}>
                <Button onClick={handleJoin}>Join Workspace</Button>
              </GridItem>
              <GridItem rowSpan={2} colSpan={2}></GridItem>
              <GridItem rowSpan={3} colSpan={2}></GridItem>
              <GridItem rowSpan={3} colSpan={6}>
                {(!!error && (
                  <Alert status="error" variant="outlined">
                    <AlertIcon as={ErrorIcon} /> {error}
                  </Alert>
                )) ||
                  ' '}
              </GridItem>
              <GridItem rowSpan={3} colSpan={2}></GridItem>
            </Grid>
          </CardBody>
        </Card>
      </Stack>
    </Page>
  )
}
