import { Link as WouterLink } from 'wouter'

import { Box, Button, Heading, List, ListItem, Text, useColorMode } from '@chakra-ui/react'

import AddIcon from '@material-design-icons/svg/sharp/add.svg?react'

export type Props = {
  link: string
  title: string
  text: string
  innerCardItems: string[]
  buttonText: string
  gridCol: number
}

// Renders a Card as a grid column
export const WorkspaceNewProjectCard = ({ link, title, text, buttonText, innerCardItems, gridCol }: Props) => {
  const colorMode = useColorMode()
  return (
    <>
      {/* Card background with radius */}
      <Box
        background="chakra-body-bg"
        width="full"
        gridRowStart={1}
        gridRowEnd={5}
        gridColumn={gridCol}
        borderRadius="2xl"
        borderWidth={1}
        borderColor={colorMode.colorMode === 'light' ? 'gray.200' : 'gray.900'}
      />

      {/* Card contents */}
      <Box gridColumn={gridCol} gridRow={1} marginX={8} marginTop={8}>
        <Heading fontWeight="semibold" fontSize="xl">
          {title}
        </Heading>
      </Box>
      <Text gridColumn={gridCol} gridRow={2} marginX={8} marginTop={6}>
        {text}
      </Text>
      <Box
        gridColumn={gridCol}
        gridRow={3}
        borderRadius="2xl"
        backgroundColor={colorMode.colorMode === 'light' ? 'gray.50' : 'whiteAlpha.200'}
        padding={4}
        marginX={8}
        marginTop={8}
        flexGrow={1}
      >
        <List styleType="none" spacing={3} textColor="chakra-placeholder-color">
          {innerCardItems.map((itemText, idx) => (
            <ListItem fontSize="sm" key={idx}>
              ⚡
              <Text as="span" fontFamily="Fira Code, var(--chakra-fonts-mono)">
                {' ' /* Adding fontFamily only to the span because the above emoji has no color on Chrome with mono */}
                {itemText}
              </Text>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box gridColumn={gridCol} gridRow={4} marginX={8} marginTop={8} marginBottom={8}>
        <WouterLink to={link}>
          <Button rightIcon={<AddIcon />} width="full" fontWeight="semibold">
            {buttonText}
          </Button>
        </WouterLink>
      </Box>
    </>
  )
}
