import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getDockerRepositories: build.query<GetDockerRepositoriesApiResponse, GetDockerRepositoriesApiArg>({
        query: () => ({ url: `/api/v2/docker/repository` }),
        providesTags: ['internal']
      }),
      getDockerTags: build.query<GetDockerTagsApiResponse, GetDockerTagsApiArg>({
        query: (queryArg) => ({ url: `/api/v2/docker/tag`, params: { repository: queryArg.repository } }),
        providesTags: ['internal']
      }),
      getDockerImageConfig: build.query<GetDockerImageConfigApiResponse, GetDockerImageConfigApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/docker/config`,
          params: { image: queryArg.image, owner: queryArg.owner, project_slug: queryArg.projectSlug }
        })
      }),
      createProjectRegistry: build.mutation<CreateProjectRegistryApiResponse, CreateProjectRegistryApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/registry`,
          method: 'POST',
          body: queryArg.projectDockerRegistry
        }),
        invalidatesTags: ['internal']
      }),
      getProjectRegistries: build.query<GetProjectRegistriesApiResponse, GetProjectRegistriesApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/registry` }),
        providesTags: ['internal']
      }),
      getProjectRegistry: build.query<GetProjectRegistryApiResponse, GetProjectRegistryApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/registry/${queryArg.dockerRegistryId}` }),
        providesTags: ['internal']
      }),
      updateProjectRegistry: build.mutation<UpdateProjectRegistryApiResponse, UpdateProjectRegistryApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/registry/${queryArg.dockerRegistryId}`,
          method: 'PUT',
          body: queryArg.projectDockerRegistry
        }),
        invalidatesTags: ['internal']
      }),
      deleteProjectRegistry: build.mutation<DeleteProjectRegistryApiResponse, DeleteProjectRegistryApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/registry/${queryArg.dockerRegistryId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['internal']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetDockerRepositoriesApiResponse = /** status 200 Fetches the list of repositories hosted on mayhem docker registry.

    Returns:
        Response - a list of repositories hosted on mayhem docker registry. */ GetDockerRepositories
export type GetDockerRepositoriesApiArg = void
export type GetDockerTagsApiResponse = /** status 200 Fetches the list of tags for given repository hosted on mayhem docker registry.

    Returns:
        Response - a list of tags for the given repository hosted on mayhem docker
        registry.
     */ GetDockerTags
export type GetDockerTagsApiArg = {
  repository: string
}
export type GetDockerImageConfigApiResponse = /** status 200 OK */ BaseSchemaWithoutTimestamps
export type GetDockerImageConfigApiArg = {
  image: string
  owner?: string
  projectSlug?: string
}
export type CreateProjectRegistryApiResponse = /** status 200
    Create a new registry for the given project
     */ ProjectDockerRegistry
export type CreateProjectRegistryApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  projectDockerRegistry: ProjectDockerRegistry
}
export type GetProjectRegistriesApiResponse = /** status 200
    Get the project registries
     */ ProjectDockerRegistryList
export type GetProjectRegistriesApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
}
export type GetProjectRegistryApiResponse = /** status 200
    Get the project registry with the given id
     */ ProjectDockerRegistry
export type GetProjectRegistryApiArg = {
  dockerRegistryId: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
}
export type UpdateProjectRegistryApiResponse = /** status 200
    Update the project registry with the given id
     */ ProjectDockerRegistry
export type UpdateProjectRegistryApiArg = {
  dockerRegistryId: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  projectDockerRegistry: ProjectDockerRegistry
}
export type DeleteProjectRegistryApiResponse = /** status 200
    Delete the project registry with the given id
     */ ProjectDockerRegistry
export type DeleteProjectRegistryApiArg = {
  dockerRegistryId: number
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
}
export type GetDockerRepositories = {
  repositories?: string[]
}
export type ApiError = {
  message?: string
}
export type GetDockerTags = {
  tags?: string[]
}
export type BaseSchemaWithoutTimestamps = {}
export type RegistryAuth = {
  created_at?: string
  updated_at?: string
  access_key_id?: string | null
  access_key?: string | null
  region?: string | null
}
export type ProjectDockerRegistry = {
  created_at?: string
  updated_at?: string
  docker_registry_id?: number
  url?: string
  name?: string
  auth?: RegistryAuth
  provider?: 'ecr' | 'harbor'
}
export type ProjectDockerRegistryList = {
  created_at?: string
  updated_at?: string
  registries?: ProjectDockerRegistry[]
}
