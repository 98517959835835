import { useState } from 'react'

import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  useToast
} from '@chakra-ui/react'

import VisibilityOffIcon from '@material-design-icons/svg/sharp/visibility_off.svg?react'
import VisibilityIcon from '@material-design-icons/svg/sharp/visibility.svg?react'

import { getMessageFromRtkError } from '../../util/errors'

import { usePutTemporaryPasswordMutation } from '@/redux/api/admin'

interface Props {
  open: boolean
  onClose: () => void

  email: string
}

export function UserSetTemporaryPasswordModal({ open, onClose, email }: Props) {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [password, setPassword] = useState('')
  const [putTemporaryPassword] = usePutTemporaryPasswordMutation()
  const toast = useToast()

  const handleClose = () => {
    setPasswordVisible(false)
    setPassword('')
    onClose()
  }

  const updateHandler = async () => {
    try {
      await putTemporaryPassword({ adminSetTemporaryPassword: { email, password } }).unwrap()
      toast({
        title: 'Password updated',
        description: `Password for user with email "${email}" was successfully updated!`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      handleClose()
    } catch (err: unknown) {
      const message = getMessageFromRtkError(err)
      toast({
        title: `Failed to update password for user with email "${email}".`,
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Set temporary password</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            The user will be asked to update the password on the first login. Please inform the user about the password change (no emails are sent).
          </Text>
          <InputGroup size="lg" marginTop="4">
            <InputLeftElement>
              <IconButton
                size="sm"
                icon={passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                onClick={(): void => {
                  setPasswordVisible(!passwordVisible)
                }}
                aria-label={passwordVisible ? 'Hide Password' : 'Show Password'}
              />
            </InputLeftElement>
            <Input value={password} type={passwordVisible ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} />
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button colorScheme="green" onClick={updateHandler} marginLeft={4} disabled={password.length < 4}>
            Set Password
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
