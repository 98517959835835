import { Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, SkeletonText, Stack, useToast } from '@chakra-ui/react'

import { Controller, FormProvider, useForm } from 'react-hook-form'

import { useEffect } from 'react'

import { useGetAccountQuery, useGetUserSettingQuery, usePutUserSettingMutation } from '../../redux/api/workspace'
import { SearchItemsCategory, SelectableGlobalSearchField } from '../search/SelectableGlobalSearchField'

type Form = {
  defaultWorkspace: string
}

export function AdminDefaultWorkspaceForm() {
  const toast = useToast()
  const { data: userSetting, isLoading: isLoadingUserSetting } = useGetUserSettingQuery()
  const [putUserSetting] = usePutUserSettingMutation()
  const { data: defaultWorkspaceData, isLoading: isLoadingDefaultWorkspace } = useGetAccountQuery(
    { owner: userSetting?.default_workspace || '' },
    { skip: !userSetting?.default_workspace }
  )

  const methods = useForm<Form>({ defaultValues: { defaultWorkspace: userSetting?.default_workspace || '' } })
  const {
    control,
    handleSubmit,
    resetField,
    setError,
    formState: { errors, isValid, isSubmitting }
  } = methods

  const onSubmit = async ({ defaultWorkspace }: Form) => {
    try {
      await putUserSetting({ userSetting: { default_workspace: defaultWorkspace ? defaultWorkspace : null } }).unwrap()
      toast({
        title: 'Default Workspace Updated',
        description: `Default workspace has been updated`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (err: unknown) {
      const message = (err as { data?: Error })?.data?.message || 'An error occurred. Please try again.'
      setError('defaultWorkspace', { type: 'custom', message: message })
    }
  }

  useEffect(() => {
    if (!isLoadingUserSetting && userSetting) {
      resetField('defaultWorkspace', { defaultValue: userSetting.default_workspace || '' })
    }
  }, [resetField, userSetting, isLoadingUserSetting])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Heading size="md">Default Workspace</Heading>
          <FormControl isInvalid={!!errors.defaultWorkspace}>
            <FormLabel>Set the default workspace for users to see/use in Mayhem:</FormLabel>
            <Controller
              control={control}
              name="defaultWorkspace"
              render={({ field: { onChange, onBlur, ref } }) =>
                isLoadingUserSetting || isLoadingDefaultWorkspace ? (
                  <SkeletonText noOfLines={1} lineHeight={4} />
                ) : (
                  <SelectableGlobalSearchField
                    placeholder="Search for a workspace..."
                    includeWorkspaces={true}
                    includeProjects={false}
                    includeUsers={false}
                    isDisabled={isSubmitting}
                    initialSelection={defaultWorkspaceData ? { ...defaultWorkspaceData, group: SearchItemsCategory.Workspaces } : undefined}
                    onChange={(change) => 'owner' in change && onChange(change.owner)}
                    onBlur={onBlur}
                    ref={ref}
                  />
                )
              }
            />
            <FormHelperText>Note: Users are able to override this with their own default. These can be seen/set in the Users page.</FormHelperText>
            {errors?.defaultWorkspace && <FormErrorMessage>{errors.defaultWorkspace.message}</FormErrorMessage>}
          </FormControl>
          <Flex justify="flex-end">
            <Button type="submit" isDisabled={!isValid} isLoading={isSubmitting || isLoadingUserSetting}>
              Save
            </Button>
          </Flex>
        </Stack>
      </form>
    </FormProvider>
  )
}
