import { Box, Card, CardBody, CardHeader, CardProps, Heading, HStack, Image, List, ListItem, Stack, useColorMode } from '@chakra-ui/react'
import { Link as ChakraLink } from '@chakra-ui/react'

import FAQVisuals1Light from '../../images/faq_visuals_1_light.png'
import FAQVisuals1Dark from '../../images/faq_visuals_1_dark.png'
import FAQVisuals2Light from '../../images/faq_visuals_2_light.png'
import FAQVisuals2Dark from '../../images/faq_visuals_2_dark.png'
import FAQVisuals3Light from '../../images/faq_visuals_3_light.png'
import FAQVisuals3Dark from '../../images/faq_visuals_3_dark.png'

export function WorkspaceNewProjectResources(cardProps: CardProps) {
  const colorMode = useColorMode().colorMode

  return (
    <Card width={{ base: 'full', xl: 'md', '2xl': 'xl' }} {...cardProps}>
      <CardHeader>
        <Heading fontWeight="semibold" fontSize="xl">
          Resources
        </Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing={6} paddingX={8}>
          <Heading size="md" fontWeight="semibold">
            Learn More
          </Heading>
          <HStack spacing={4}>
            <Image src={colorMode === 'light' ? FAQVisuals1Light : FAQVisuals1Dark} width="48px" height="48px" />
            <ChakraLink variant="brand" href="https://docs.mayhem.security/code-testing/reference/mayhem-cli-commands/" isExternal>
              CLI Reference
            </ChakraLink>
          </HStack>
          <HStack spacing={4}>
            <Image src={colorMode === 'light' ? FAQVisuals2Light : FAQVisuals2Dark} width="48px" height="48px" />
            <ChakraLink variant="brand" href="https://docs.mayhem.security/code-testing/by-language/autosar/" isExternal>
              Testing embedded systems with Mayhem
            </ChakraLink>
          </HStack>
          <HStack spacing={4}>
            <Image src={colorMode === 'light' ? FAQVisuals3Light : FAQVisuals3Dark} width="48px" height="48px" />
            <ChakraLink variant="brand" href="https://docs.mayhem.security/code-testing/reference/support-matrix/" isExternal>
              Supported Languages and Architectures
            </ChakraLink>
          </HStack>
          <Heading size="md" fontWeight="semibold" paddingTop={6}>
            Most Popular Docs
          </Heading>
          <Box>
            <List spacing={6} fontSize="md">
              <ListItem>
                <ChakraLink variant="brand" href="https://docs.mayhem.security/code-testing/summary/" isExternal>
                  Testing Code with Mayhem
                </ChakraLink>
              </ListItem>
              <ListItem>
                <ChakraLink variant="brand" href="https://docs.mayhem.security/api-testing/summary/" isExternal>
                  Testing APIs with Mayhem
                </ChakraLink>
              </ListItem>
              <ListItem>
                <ChakraLink variant="brand" href="https://docs.mayhem.security/dynamic-sbom/summary/" isExternal>
                  Mayhem’s Dynamic SBOM Service
                </ChakraLink>
              </ListItem>
            </List>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  )
}
