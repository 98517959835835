import { skipToken } from '@reduxjs/toolkit/query'
import { useRoute } from 'wouter'
import { useDispatch } from 'react-redux'

import { useMemo } from 'react'

import { WorkspaceDefaultRedirect } from './WorkspaceDefaultRedirect'

import { useGetAccountQuery } from '@/redux/api/workspace'

import { WorkspaceSidebar } from '@/features/workspace-sidebar/WorkspaceSidebar'

import { Shell } from '@/components/Shell/Shell'

import { clearMruWorkspace } from '@/features/workspace-dashboard/mru.slice'
import { useGetDefaultWorkspace, usePermissions } from '@/hooks'
import { MayhemPageLoader } from '@/components/MayhemPageLoader'

interface Props {
  children: JSX.Element
}

export const WorkspaceLayout = ({ children }: Props) => {
  const dispatch = useDispatch()

  const [_, workspaceParams] = useRoute('/:workspaceSlug/*?')
  const [__, projectParams] = useRoute('/:workspaceSlug/:projectSlug/*?')
  const matchDsbomCompare = useRoute('/:workspaceSlug/-/dynamic-sbom-compare')[0]

  const matchUserSettings = useRoute('/-/settings/user/*?')[0]
  const matchWorkspaceSettings = useRoute('/:workspaceSlug/-/settings/*?')[0]
  const matchIntegrations = useRoute('/:workspaceSlug/-/integrations')[0]
  const matchAdminSettings = useRoute('/-/settings/admin/*?')[0]
  const matchPlan = useRoute('/:workspaceSlug/-/plan')[0]
  const matchSettings = matchUserSettings || matchAdminSettings || matchWorkspaceSettings || matchIntegrations || matchPlan

  if (!workspaceParams) {
    // this should only happen if the workspace layout is rendered without matching any workspace
    throw new Error('Workspace parameter was not matched')
  }
  const { defaultWorkspace } = useGetDefaultWorkspace()
  const workspaceSlug = workspaceParams?.workspaceSlug === '-' ? defaultWorkspace : workspaceParams?.workspaceSlug
  const projectSlug = workspaceParams?.workspaceSlug === '-' ? '-' : projectParams?.projectSlug

  const submenu = matchSettings ? 'settings' : projectSlug && projectSlug !== '-' ? 'project' : undefined

  const workspaceSidebar = useMemo(
    () => <WorkspaceSidebar workspaceSlug={workspaceSlug!} projectSlug={projectSlug} submenu={submenu} />,
    [workspaceSlug, projectSlug, submenu]
  )

  const { isError } = useGetAccountQuery(workspaceSlug ? { owner: workspaceSlug } : skipToken)

  const { isError: orgMemberError, userSlug, isAdmin } = usePermissions(workspaceSlug || '', { skip: !workspaceSlug })

  // Only admins or workspace members can view the workspace page. If viewing a project,
  // this check is skipped, since projects may be shared beyond a workspace with external
  // collaborators.
  const isWorkspaceViewBlocked = projectSlug === '-' && !isAdmin && userSlug !== workspaceSlug && !!orgMemberError

  if (!workspaceSlug) {
    return <MayhemPageLoader />
  }

  if (isError || isWorkspaceViewBlocked) {
    dispatch(clearMruWorkspace())
    return <WorkspaceDefaultRedirect />
  }

  return (
    <Shell sidebar={workspaceSidebar} submenuOpen={!!submenu} showPageAccentBackground={matchDsbomCompare}>
      {children}
    </Shell>
  )
}
