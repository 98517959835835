import { Modal, ModalOverlay, ModalContent, ModalCloseButton, Text, VStack, ModalBody } from '@chakra-ui/react'

import { Registry } from './utils'

import { CreateRegistryForm } from './CreateRegistryForm'

// TODO refactor the prop drilling
interface RegistryModalProps {
  isOpen: boolean
  onClose: () => void
  registry?: Registry
  requireAuth?: boolean
  allowPinging?: boolean
  owner: string
  projectSlug: string
  isEditMode: boolean
}

export function RegistryModal({
  owner,
  projectSlug,
  isOpen,
  onClose,
  registry,
  requireAuth = false,
  allowPinging = true,
  isEditMode
}: RegistryModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody borderRadius="xl">
          <VStack alignItems="flex-start" justifyContent="flex-start" gap="6" marginTop="6">
            <Text fontWeight="semibold" fontSize="xl">
              Add New Registry
            </Text>
            <CreateRegistryForm
              owner={owner}
              projectSlug={projectSlug}
              registry={registry}
              isEditMode={isEditMode}
              onClose={onClose}
              allowPinging={allowPinging}
              requireAuth={requireAuth}
            />
          </VStack>
          <ModalCloseButton />
          <VStack></VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
