import { FormProvider, useForm } from 'react-hook-form'

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Select,
  VStack,
  Text,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react'

import isURL from 'validator/lib/isURL'

import { useState } from 'react'

import { useCreateProjectRegistryMutation, useUpdateProjectRegistryMutation } from '../../redux/api/docker'

import { Registry } from './utils'

type RegistryForm = {
  accessID?: string
  accessKey?: string
} & Registry

interface RegistryEditFormProps {
  owner: string
  projectSlug: string
  registry?: Registry
  projects?: string[]
  isEditMode: boolean
  onClose: () => void
  requireAuth?: boolean
  allowPinging?: boolean
}

interface ActionResult {
  status: 'success' | 'error'
  title: string
  message: string
}

export function CreateRegistryForm({ owner, projectSlug, isEditMode, registry, onClose, requireAuth, allowPinging = true }: RegistryEditFormProps) {
  const [actionResult, setActionResult] = useState<ActionResult | null>(null)
  const [createRegistry] = useCreateProjectRegistryMutation()
  const [editRegistry] = useUpdateProjectRegistryMutation()

  const onSubmit = async (data: RegistryForm) => {
    if (isEditMode) {
      editRegistryAction(data)
    } else {
      createRegistryAction(data)
    }
  }

  const createRegistryAction = async (data: RegistryForm) => {
    try {
      await createRegistry({
        owner: owner,
        projectSlug: projectSlug,
        projectDockerRegistry: {
          url: data.endpointURL,
          name: data.name,
          provider: data.provider as 'ecr' | 'harbor',
          auth: requireAuth
            ? {
                access_key_id: data.accessID,
                access_key: data.accessKey
              }
            : undefined
        }
      }).unwrap()
      setActionResult({
        status: 'success',
        title: 'Registry added successfully',
        message: 'Your registry was successfully added and is now accessible when you create a new run in this project. 🎉 '
      })
    } catch (e) {
      setActionResult({
        status: 'error',
        title: 'Error adding registry',
        message: 'We were not able to add the registry. Please try again later.'
      })
    }
  }

  const editRegistryAction = async (data: RegistryForm) => {
    try {
      if (!registry?.registryID) {
        return
      }
      await editRegistry({
        owner: owner,
        projectSlug: projectSlug,
        dockerRegistryId: registry?.registryID,
        projectDockerRegistry: {
          url: data.endpointURL,
          name: data.name,
          provider: data.provider as 'ecr' | 'harbor',
          auth: requireAuth
            ? {
                access_key_id: data.accessID,
                access_key: data.accessKey
              }
            : undefined
        }
      }).unwrap()
      setActionResult({
        status: 'success',
        title: 'Registry updated successfully',
        message: 'Your registry was successfully updated and is now accessible when you create a new run in this project. 🎉 '
      })
    } catch (e) {
      setActionResult({
        status: 'error',
        title: 'Error updating registry',
        message: 'We were not able to update the registry. Please try again later.'
      })
    }
  }

  const handlePing = () => {
    setActionResult({
      status: 'error',
      title: 'Error reaching registry',
      message: 'We were not able to reach the registry. Please check the endpoint URL and try again.'
    })
  }
  const urlValidator = (value: string) => {
    // TODO can extend with provider specific patterns (eg for ECR *.dkr.ecr.*.amazonaws.com) etc
    return isURL(value, { protocols: ['http', 'https'] })
  }

  const methods = useForm<RegistryForm>({
    defaultValues: {
      ...registry
    }
  })
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting }
  } = methods

  return (
    <FormProvider {...methods}>
      {actionResult && (
        <Alert status={actionResult.status} borderRadius="lg">
          <AlertIcon />
          <VStack alignItems="flex-start" marginLeft="2">
            <AlertTitle>{actionResult.title}</AlertTitle>
            <AlertDescription>{actionResult.message}</AlertDescription>
          </VStack>
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <VStack gap="5" justifyContent="center" marginBottom="9">
          {/* TODO this doesnt make sense for the form displayed from the project registries page */}
          {/* <Text>1. Specify the projects in which you would like to access this registry</Text>
          <FormControl isInvalid={!!errors.projects} isRequired>
            <FormLabel>Projects</FormLabel>
            <Input {...register('projects', { required: true })} placeholder="The projects you would like to access this registry" />
            <FormErrorMessage>{errors.projects?.message}</FormErrorMessage>
          </FormControl>
          <Divider /> */}
          <FormControl isInvalid={!!errors.name} isRequired>
            <FormLabel>Provider</FormLabel>
            <Select {...register('provider', { required: true })} placeholder="Select your registry provider">
              <option value="harbor">Harbor</option>
              <option value="ecr">ECR</option>
            </Select>
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.name} isRequired>
            <FormLabel>Registry Name</FormLabel>
            <Input {...register('name', { required: true, maxLength: 128 })} placeholder="The name you would like to use for the registry" />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.endpointURL} isRequired isDisabled={isSubmitting}>
            <FormLabel>Endpoint URL</FormLabel>
            <Input {...register('endpointURL', { required: true, validate: urlValidator })} placeholder="Your registry's endpoint" />
            <FormHelperText>The Registry&apos;s endpoint URL that Mayhem will use to pull images.</FormHelperText>
            <FormErrorMessage>{errors.endpointURL?.message}</FormErrorMessage>
          </FormControl>
          {/* TODO extend with provider specific fields (eg for ECR, Harbor etc) for authentication, right now
          both ecr and harbor use a access id and client key for auth*/}
          <>
            <Divider />
            {/* TODO [MH-17665] add tooltip that directs to docs with instructions on how the user can get the access ID and key */}
            <Text>2. Provide the authentication details for the registry</Text>
            <FormControl isInvalid={!!errors.accessID} isRequired>
              <FormLabel>Access ID</FormLabel>
              <Input {...register('accessID', { required: true })} placeholder="The access ID for the registry" />
              <FormErrorMessage>{errors.accessID?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.accessKey} isRequired>
              <FormLabel>Access Key</FormLabel>
              <Input
                autoComplete="new-password"
                type="password"
                {...register('accessKey', { required: true })}
                placeholder="The access key for the registry"
              />
              <FormErrorMessage>{errors.accessKey?.message}</FormErrorMessage>
            </FormControl>
          </>
          <HStack justifyContent={allowPinging ? 'space-between' : 'flex-end'} width="100%">
            {allowPinging && (
              <Button variant="outline" onClick={handlePing}>
                {' '}
                Test Connection
              </Button>
            )}
            <HStack>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button isLoading={isSubmitting} isDisabled={!isValid} type="submit" justifyContent="flex-end">
                {isEditMode ? 'Save' : 'Add Registry'}
              </Button>
            </HStack>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  )
}
