import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { cardAnatomy } from '@chakra-ui/anatomy'
import { mode, type StyleFunctionProps } from '@chakra-ui/theme-tools'
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)
const cardBaseStyle = definePartsStyle((props: StyleFunctionProps) => ({
  container: {
    background: 'chakra-body-bg',
    borderWidth: '1px',
    borderRadius: '2xl',
    borderColor: mode('gray.200', 'gray.900')(props),
    overflow: 'clip' // for cutting off bottom row corners if no pagination
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexShrink: 0,
    paddingX: '8',
    paddingTop: 8,
    paddingBottom: 6,
    borderTopLeftRadius: '2xl',
    borderTopRightRadius: '2xl',
    borderColor: 'inherit',
    minHeight: '14',
    // for cards using both a title header and an actions (filters, search, etc) header
    '&:nth-of-type(n+2)': {
      paddingTop: 0
    }
  },
  body: {
    padding: 0
  },
  footer: {
    borderColor: 'inherit',
    padding: '4'
  }
}))

const cardVariants = {
  base: {},
  info: definePartsStyle({
    container: {
      borderColor: 'accent'
    },
    header: {
      bg: 'accent',
      borderColor: 'accent',
      color: 'chakra-body-bg'
    }
  }),
  warning: definePartsStyle({
    container: {
      borderColor: 'orange.600'
    },
    header: {
      bg: 'orange.600',
      borderColor: 'orange.600',
      color: 'chakra-body-bg'
    }
  }),
  bad: definePartsStyle({
    container: {
      borderColor: 'bad'
    },
    header: {
      bg: 'bad',
      borderColor: 'bad',
      color: 'chakra-body-bg'
    }
  }),
  'no-border': definePartsStyle({
    container: {
      borderRadius: '4xl',
      padding: 8,
      borderWidth: 0
    },
    header: {
      paddingX: 0,
      paddingTop: 0,
      paddingBottom: 6,
      minHeight: 0
    },
    footer: {
      paddingTop: 8
    }
  })
}
export const Card = defineMultiStyleConfig({
  baseStyle: cardBaseStyle,
  variants: cardVariants,
  defaultProps: { variant: 'base' }
})
