import { useDisclosure, HStack, Button, Text, VStack, Heading } from '@chakra-ui/react'

import { useEffect } from 'react'

import { TableCard } from '../../components/Card/TableCard'
import ListingTable, { Column } from '../../components/ListingTable/ListingTable'
import { SearchBar } from '../../components/SearchBar'
import { RegistryModal } from '../registry-management/RegistryModal'
import { Registry } from '../registry-management/utils'
import { Page } from '../../components/Page'
import { useGetProjectMemberPermissionQuery, useGetProjectQuery } from '../../redux/api/projects'
import { getProjectsSettingsPageTitle } from '../../titles'
import { MayhemBreadcrumb } from '../../components/MayhemBreadcrumb'
import { useAppSelector } from '../../hooks'
import { getCurrentUserInfo } from '../auth/utils'
import { useGetProjectRegistriesQuery } from '../../redux/api/docker'
import { RegistryEditAction } from '../registry-management/RegistryEditAction'

interface ProjectRegistriePagesProps {
  workspaceSlug: string
  projectSlug: string
}

export const getRegistriesColumns: (excludeUsedBy?: boolean) => Column<Registry>[] = (excludeUsedBy = false) => {
  const cols: Column<Registry>[] = [
    { key: 'name', headingText: 'Registry', sorting: false, cell: (row: Registry) => row.name },
    { key: 'endpointURL', headingText: 'Endpoint URL', sorting: false, cell: (row: Registry) => row.endpointURL },
    { key: 'provider', headingText: 'Provider', sorting: false, cell: (row: Registry) => row.provider }
  ]
  if (!excludeUsedBy) {
    cols.push({
      key: 'usedBy',
      headingText: 'Used By',
      sorting: false,
      cell: (row: Registry) => row.usedBy.join(', ')
    })
  }
  cols.push({
    key: 'action',
    headingText: 'Action',
    sorting: false,
    cellHeaderAlignText: 'right' as 'center' | 'right',
    cellAlignText: 'right' as 'left' | 'right',
    cell: (registry: Registry) => <RegistryEditAction registry={registry} />
  })
  return cols
}

export function ProjectRegistriesPage({ workspaceSlug, projectSlug }: ProjectRegistriePagesProps) {
  const { data: projectRegistries } = useGetProjectRegistriesQuery({ owner: workspaceSlug, projectSlug })
  const { onOpen, onClose, isOpen } = useDisclosure()

  const { userSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner: workspaceSlug,
    projectSlug,
    userSlug
  })

  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery({ owner: workspaceSlug, projectSlug })
  const { owner_name: workspaceName, project_name: projectName } = project || {}

  useEffect(() => {
    if (workspaceName && projectName) {
      document.title = getProjectsSettingsPageTitle(workspaceName, projectName, 'Registries')
    }
  }, [workspaceName, projectName])

  if (!isMayhemAdmin && projectPermission?.permission !== 'ADMIN') {
    // TODO handle non admin or project owners (could possibly be moved to a newer component like AdminRoute)
    return <></>
  }
  // if (!projectRegistries) {
  //   return <></>
  // }
  const registries = projectRegistries?.registries || []
  // TODO fix nullable values in the registry object
  const items: Registry[] = registries.map((registry) => {
    return {
      name: registry.name || '',
      endpointURL: registry.url || '',
      provider: registry.provider || '',
      usedBy: [],
      workspaceSlug,
      projectSlug,
      registryID: registry.docker_registry_id || 0
    }
  })

  return (
    <Page
      header={
        <MayhemBreadcrumb
          isLoading={isProjectLoading}
          items={[
            { text: 'Projects', path: `/${workspaceSlug}/-/projects` },
            { text: projectName!, path: `/${workspaceSlug}/${projectSlug}` },
            { text: 'Registries' }
          ]}
        />
      }
    >
      <Heading mb={8}>Registries</Heading>
      <TableCard
        title="This Project's registries"
        headerActions={
          <>
            <HStack>
              <Button onClick={onOpen}>Connect Registry</Button>
              <RegistryModal
                owner={workspaceSlug}
                projectSlug={projectSlug}
                isOpen={isOpen}
                onClose={onClose}
                requireAuth={true}
                isEditMode={false}
              />
            </HStack>
          </>
        }
        actions={
          <VStack width="full" alignItems="flex-start" gap="4">
            <Text>List of all registries in your project, including external registries. Registries are unique to project(s).</Text>
            <SearchBar placeholder="Search for a registry" InputGroupProps={{ flex: '1 1' }} />
          </VStack>
        }
      >
        <ListingTable
          rowKey={(row) => row.name}
          itemName="registry"
          items={items}
          isLoading={false}
          isError={false}
          columns={getRegistriesColumns(true)}
          count={items.length}
        />
      </TableCard>
    </Page>
  )
}
