// From https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
//
// While it's possible to import the RootState and AppDispatch types into
// each component, it's better to create pre-typed versions of the useDispatch
// and useSelector hooks for usage in your application. This is important for
// a couple of reasons:
//
// * For "useSelector", it saves you the need to type (state: RootState) every time
// * For "useDispatch", the default Dispatch type does not know about thunks or
//   other middleware. In order to correctly dispatch thunks, you need to use
//   the specific customized AppDispatch type from the store that includes the
//   thunk middleware types, and use that with useDispatch. Adding a pre-typed
//   "useDispatch" hook keeps you from forgetting to import AppDispatch where it's needed.
//
// Since these are actual variables, not types, it's important to define them in
// a separate file such as app/hooks.ts, not the store setup file. This allows you
// to import them into any component file that needs to use the hooks, and avoids
// potential circular import dependency issues.

import { DependencyList, useEffect, useState } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { skipToken } from '@reduxjs/toolkit/query'

import { AUTOREFRESH_INTERVAL } from './redux/api/mayhemApi'
import { useSearch } from './hooks.wouter'

import type { RootState, AppDispatch } from './redux/store'

import { useGetOrgMemberQuery, useGetUserSettingQuery, useWhoamiQuery } from '@/redux/api/workspace'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useQuery() {
  const search = useSearch()
  return new URLSearchParams(search)
}

export enum IntervalLifecycle {
  Init = 'init',
  Interval = 'interval'
}

/**
 * @param action The action to perform at an interval.
 * @param interval The number of milliseconds between executions of action.
 * @param deps If present, effect will only activate if the values in the list change.
 */
export const useInterval = ({
  action,
  interval = AUTOREFRESH_INTERVAL,
  deps = []
}: {
  action: (lifecycle: IntervalLifecycle) => void
  interval?: number
  deps?: DependencyList
}) => {
  useEffect(() => {
    action(IntervalLifecycle.Init)

    const handle = window.setInterval(() => {
      action(IntervalLifecycle.Interval)
    }, interval)

    return () => {
      window.clearInterval(handle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, ...deps])
}

/**
 *
 * @param value The debounce value.
 * @param delay The delay in milliseconds.
 */
export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handle = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handle)
    }
  }, [value, delay])

  return debouncedValue
}

/**
 * Check current user's owner permission for workspace as well as admin permission
 * @param {string} workspaceSlug Current workspace of user
 * @param {object} options with keys: skip: whether to skip the query
 * @return {object} Booleans indicating if current user has aformentioned permissions
 */
export function usePermissions(workspaceSlug: string, { skip }: { skip?: boolean } = {}) {
  const { data: profile } = useWhoamiQuery()
  const isAdmin = Boolean(profile?.is_admin)

  const { data: currentMember, isError } = useGetOrgMemberQuery(
    !profile
      ? skipToken
      : {
          owner: workspaceSlug,
          userSlug: profile.slug as string
        },
    { skip }
  )
  const { owns: isOwner } = { ...currentMember }

  return {
    isAdmin,
    isOwner,
    allowWrite: Boolean(isAdmin || isOwner),
    userSlug: profile?.slug,
    isError
  }
}

/**
 * Retrieves the default workspace of an user, in this priority order:
 * 1. Most recently used (from local storage), or
 * 2. User's default workspace, or
 * 3. Deployment's default workspace, or
 * 4. User's personal workspace
 */
export function useGetDefaultWorkspace(): { defaultWorkspace?: string; isLoading?: boolean; isError?: boolean } {
  const { isLoading: isLoadingProfile, data: profile, error: currentUserError } = useWhoamiQuery()
  const { data: deploymentSettings, isLoading: isLoadingDeploymentSettings, error: deploymentSettingsError } = useGetUserSettingQuery()

  // `mru` could be a workspace that the user does not belong/have access to, but the call
  // to fetch the workspace will fail and clear the redux store of that value eventually.
  //
  // This assumes that there is an RTK query middleware that clears the `mru`
  // if any GET account response returned 403 or 404 (see /src/redux/middelware.ts).
  const mostRecentlyUsedWorkspace = useSelector((state: RootState) => state.workspace.mru)
  if (mostRecentlyUsedWorkspace) {
    return { defaultWorkspace: mostRecentlyUsedWorkspace }
  }

  if (currentUserError || deploymentSettingsError) {
    return { isError: true }
  }
  if (isLoadingProfile || isLoadingDeploymentSettings) {
    return { isLoading: true }
  }
  if (profile?.default_workspace_slug) {
    return { defaultWorkspace: profile.default_workspace_slug }
  }
  if (deploymentSettings?.default_workspace) {
    return { defaultWorkspace: deploymentSettings.default_workspace }
  }
  return { defaultWorkspace: profile!.slug }
}

/**
 * The current user can create a workspace if:
 * 1. The deployment setting allows non-admins to create workspaces, or
 * 2. The user is an admin
 */
export function useCanCreateWorkspace() {
  const { data: deploymentSettings } = useGetUserSettingQuery()
  const { data: user } = useWhoamiQuery()

  return deploymentSettings?.nonadmin_workspace_creation_enabled || user?.is_admin
}
