import { Page } from '@/components/Page'

import { AdminRunsPage } from '@/features/admin-runs/AdminRunsPage'
import { AdminSettings } from '@/features/admin-settings/AdminSettings'
import { AdminWebhooksPage } from '@/features/admin-webhooks/AdminWebhooksPage'
import { AdminWorkersPage } from '@/features/admin-workers/AdminWorkersPage'
import { AdminWorkspacesPage } from '@/features/admin-settings/AdminWorkspacesPage'
import { DataTab, DataTabs } from '@/features/../components/DataTabs'
import { IntegrationsAdminSettings } from '@/features/admin-settings/IntegrationsAdminSettings'
import { ReportingDashboardPage } from '@/features/admin-reports/ReportingDashboardPage'
import { UsersList } from '@/features/admin-users/UsersList'
import { WorkersFullNotice } from '@/features/runs/WorkersFullNotice'

export function AdminPage() {
  const tabData: DataTab[] = [
    {
      label: 'Settings',
      content: <AdminSettings />,
      route: '/-/settings/admin'
    },
    {
      label: 'Reporting Dashboard',
      content: <ReportingDashboardPage />,
      route: `/-/settings/admin/reports`
    },
    {
      label: 'Users',
      content: <UsersList />,
      route: `/-/settings/admin/users`
    },
    {
      label: 'Workspaces',
      content: <AdminWorkspacesPage />,
      route: `/-/settings/admin/workspaces`
    },
    {
      label: 'Integrations',
      content: <IntegrationsAdminSettings />,
      route: `/-/settings/admin/integrations`
    },
    {
      label: 'Webhooks',
      content: <AdminWebhooksPage />,
      route: `/-/settings/admin/webhooks`
    },
    // {
    //   label: 'Registries',
    //   content: <AdminRegistries />,
    //   route: `/-/settings/admin/registries`
    // },
    {
      label: 'Runs',
      content: <AdminRunsPage />,
      route: `/-/settings/admin/runs`
    },
    {
      label: 'Workers',
      content: <AdminWorkersPage />,
      route: '/-/settings/admin/workers'
    }
  ]

  return (
    <Page header="Admin Settings">
      <WorkersFullNotice />

      <DataTabs isLazy variant="line" width="full" tabs={tabData} />
    </Page>
  )
}
